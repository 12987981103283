// miscellaneous stylings
// width styles
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.minW-10 {
  min-width: 10%;
}
.minW-20 {
  min-width: 20%;
}
.minW-30 {
  min-width: 30%;
}
.minW-40 {
  min-width: 40%;
}
.minW-50 {
  min-width: 50%;
}
.minW-60 {
  min-width: 60%;
}
.minW-70 {
  min-width: 70%;
}
.minW-80 {
  min-width: 80%;
}
.minW-90 {
  min-width: 90%;
}
.minW-100 {
  min-width: 100%;
}


.w-10p {
  width: 10px;
}
.w-20p {
  width: 20px;
}
.w-30p {
  width: 30px;
}
.w-40p {
  width: 40px;
}
.w-50p {
  width: 50px;
}
.w-100p {
  width: 100px;
}
.w-150p {
  width: 150px;
}
.w-200p {
  width: 200px;
}
.w-250p {
  width: 250px;
}
.w-300p {
  width: 300px;
}
.w-350p {
  width: 350px;
}
.w-400p {
  width: 400px;
}
.w-450p {
  width: 450px;
}
.w-500p {
  width: 500px;
}
.w-550p {
  width: 550px;
}
.w-600p {
  width: 600px;
}
.w-650p {
  width: 650px;
}
.w-700p {
  width: 700px;
}

// min widths
.minW-50p {
  min-width: 50px;
}
.minW-100p {
  min-width: 100px;
}
.minW-150p {
  min-width: 150px;
}
.minW-200p {
  min-width: 200px;
}
.minW-250p {
  min-width: 250px;
}
.minW-300p {
  min-width: 300px;
}
.minW-350p {
  min-width: 350px;
}
.minW-400p {
  min-width: 400px;
}
.minW-450p {
  min-width: 450px;
}
.minW-500p {
  min-width: 500px;
}
.minW-550p {
  min-width: 550px;
}
.minW-600p {
  min-width: 600px;
}
.minW-650p {
  min-width: 650px;
}
.minW-700p {
  min-width: 700px;
}

// height styles
.h-50p {
  height: 50px;
}
.h-100p {
  height: 100px;
}
.h-150p {
  height: 150px;
}
.h-200p {
  height: 200px;
}
.h-250p {
  height: 250px;
}
.h-300p {
  height: 300px;
}
.h-350p {
  height: 350px;
}
.h-400p {
  height: 400px;
}
.h-450p {
  height: 450px;
}
.h-500p {
  height: 500px;
}

// min height styles
.minH-50p {
  min-height: 50px;
}
.minH-100p {
  min-height: 100px;
}
.minH-150p {
  min-height: 150px;
}
.minH-200p {
  min-height: 200px;
}
.minH-250p {
  min-height: 250px;
}
.minH-300p {
  min-height: 300px;
}
.minH-350p {
  min-height: 350px;
}
.minH-400p {
  min-height: 400px;
}
.minH-450p {
  min-height: 450px;
}
.minH-500p {
  min-height: 500px;
}
.minH-550p {
  min-height: 550px;
}
.minH-600p {
  min-height: 600px;
}
.minH-650p {
  min-height: 650px;
}
.minH-700p {
  min-height: 700px;
}
.minH-750p {
  min-height: 750px;
}
.minH-800p {
  min-height: 800px;
}
.minH-850p {
  min-height: 850px;
}
.minH-900p {
  min-height: 900px;
}

// max height styles
.maxH-50p {
  max-height: 50px;
}
.maxH-100p {
  max-height: 100px;
}
.maxH-150p {
  max-height: 150px;
}
.maxH-200p {
  max-height: 200px;
}
.maxH-250p {
  max-height: 250px;
}
.maxH-300p {
  max-height: 300px;
}
.maxH-350p {
  max-height: 350px;
}
.maxH-400p {
  max-height: 400px;
}
.maxH-450p {
  max-height: 450px;
}
.maxH-500p {
  max-height: 500px;
}
.maxH-550p {
  max-height: 550px;
}
.maxH-600p {
  max-height: 600px;
}
.maxH-650p {
  max-height: 650px;
}
.maxH-700p {
  max-height: 700px;
}
.maxH-750p {
  max-height: 750px;
}
.maxH-800p {
  max-height: 800px;
}
.maxH-850p {
  max-height: 850px;
}
.maxH-900p {
  min-height: 900px;
}
// padding styles

// texts
.fs-15p {
  font-size: 15px;
}
.fs-14p {
  font-size: 14px;
}
.fs-13p {
  font-size: 13px;
}
.fs-12p {
  font-size: 12px;
}
.fs-11p {
  font-size: 11px;
}
.fs-10p {
  font-size: 10px;
}
.fs-9p {
  font-size: 9px;
}
.fs-8p {
  font-size: 8px;
}
.fs-7p {
  font-size: 7px;
}
.fs-6p {
  font-size: 6px;
}
.fs-5p {
  font-size: 5px;
}

// padding styles

// home styling
.home_bg {
  width: 100%;
  height: 100vh;
  background: linear-gradient(#41b8833b, #41b8832a), url(./images/home/bg.png);
  // background: linear-gradient(), url(./ /resources/images/home/bg.png);
  background-size: cover;

  .home_circle_bg {
    width: 450px;
    height: 450px;
    background-color: #41b88360;
    border-radius: 300px;
    // position: absolute;
    // top: 100px;
  }
}

.pt-100p {
  padding-top: 100px;
}

.container1 {
  display: flex;
}

.divider {
  width: 0.5px;
  background-color: rgb(224, 223, 223);
  margin-left: 30px;
  margin-right: 10px;
  height: 10cm;
}

.label {
  background-color: #ff9800;
  padding: 8px;
  color: white;
}

.pointer-cursor{
  cursor: pointer;
}
